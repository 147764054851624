<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      width="auto"
      app
      fixed
      temporary
      left
      color="rgba(255, 255, 255, 0.25)"
      overlay-color="rgba(255, 255, 255, 0)"
      mobile-breakpoint="0"
      class="drawer frosted-glass-25 ems-opacity-25-bg"
    >
      <LeftSideList />
      <template v-slot:append>
        <v-row class="caption ma-0 text--secondary">
          <v-col cols="2" class="py-0">web</v-col>
          <v-col cols="auto" class="py-0">{{ settings.version }}</v-col>
          <v-col cols="auto" class="py-0 ml-auto">{{
            moment(settings.versionTime).format('YYYY-MM-DD HH:mm')
          }}</v-col>
        </v-row>
        <v-row class="caption ma-0 text--secondary" v-if="apiVersion">
          <v-col cols="2" class="py-0">api</v-col>
          <v-col cols="auto" class="py-0">{{ apiVersion.version }}</v-col>
          <v-col cols="auto" class="py-0 ml-auto">{{
            moment(apiVersion.timestamp).format('YYYY-MM-DD HH:mm')
          }}</v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      v-if="!isAPPView"
      fixed
      app
      height="64px"
      flat
      color="ems-header"
    >
      <v-app-bar-nav-icon
        dark
        @click="drawer = !drawer"
        :disabled="!$store.getters['page/ipcStatus']"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" class="text-decoration-none white--text">
          <div class="text-h6 font-weight-bold">{{ settings.title }}</div>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom offset-y v-if="settingPermission">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-flex"
            v-bind="attrs"
            text
            dark
            v-on="on"
            :disabled="!$store.getters['page/ipcStatus']"
          >
            <v-icon class="mx-1">mdi-tune</v-icon>
            <span class="align-self-center font-weight-bold">
              {{ settingMenu.label }}
            </span>
          </v-btn>
        </template>
        <v-list flat class="ems-opacity-75-bg frosted-glass">
          <v-list-item
            class="ems-opacity-bg frosted-glass"
            color="primary"
            v-for="item in filteredItems(settingMenu.dropDown)"
            :to="`${schoolUrl}/${item.to}`"
            :disabled="item.disabled"
            :key="item.key"
          >
            {{ item.label }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        bottom
        offset-y
        v-if="userRole.role === 'SCH_ADMIN' || userRole.role === 'SCH_AFFAIRS'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="d-flex" v-bind="attrs" text dark v-on="on">
            <v-icon class="mx-1">mdi-help-circle-outline</v-icon>
            <span class="align-self-center font-weight-bold"> 幫助中心 </span>
          </v-btn>
        </template>
        <v-list flat class="ems-opacity-75-bg frosted-glass">
          <v-list-item
            class="ems-opacity-bg frosted-glass"
            color="primary"
            @click="$router.push({ name: 'IssueReport' })"
            v-if="!isIlan"
          >
            新增報修
          </v-list-item>
          <v-list-item
            class="ems-opacity-bg frosted-glass"
            color="primary"
            @click="$router.push({ name: 'OperateRecord' })"
          >
            操作紀錄
          </v-list-item>
          <v-list-item class="ems-opacity-bg frosted-glass" color="primary">
            <a
              href="/report/manual/EMS操作手冊.pdf"
              class="text-decoration-none"
              style="color: rgba(0, 0, 0, 0.87)"
              download
            >
              使用手冊下載
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu left offset-y :min-width="160">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon large>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list v-if="user">
          <v-list-item>
            <v-list-item-title>Hi, {{ user.user_name }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isCityAdmin" to="/line/OAuth">
            綁定 LINE
          </v-list-item>
          <v-list-item to="/line/setting"> 告警通知設定 </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon> mdi-lock </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> 登出</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item to="/login"> 登入 </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <div class="animated fadeIn">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
import settings from '@/settings'
import { mapActions, mapGetters } from 'vuex'
import LeftSideList from './LeftSideList.vue'
import apiConfig from '@/api/config'

export default {
  name: 'DefaultContainer',
  components: {
    LeftSideList
  },
  data() {
    return {
      settings: settings,
      drawer: false,
      // drawer: true
      settingMenu: {
        label: '設定管理',
        to: '',
        roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
        disabled: false,
        iconImg: '@/assets/image/icon_Settings.svg',
        dropDown: [
          {
            label: '冷氣控制',
            to: 'action',
            roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: [
              'GET_AC',
              'GET_AC_CONTROL',
              'AC_CONTROL_SCHOOL',
              'AC_CONTROL_CLASS'
            ],
            disabled: false
          },
          {
            label: '群組設定',
            to: 'group',
            roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['GET_AC'],
            disabled: false
          },
          {
            label: '帳號管理',
            to: 'account',
            roles: ['SCH_ADMIN'],
            permission: [
              'GET_ACCOUNT_LIST',
              'GET_ACCOUNT',
              'DELETE_ACCOUNT',
              'NEW_ACCOUNT',
              'UPDATE_ACCOUNT',
              'GET_PERMISSIONS',
              'GET_PERMISSION_LIST'
            ],
            disabled: false
          },
          {
            label: '排程管理',
            to: 'schedule',
            roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: [],
            disabled: false
          },
          {
            label: '超約卸載',
            to: 'unloading',
            roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: [],
            disabled: false
          },
          {
            label: 'ADR資訊',
            to: 'ADR/history',
            roles: ['SCH_ADMIN'],
            permission: [],
            disabled: false
          },
          {
            label: '設備管理',
            to: 'device',
            roles: ['SCH_ADMIN'],
            permission: [],
            disabled: false
          }
          // {
          //   label: '新風參數設定',
          //   to: 'ventilation-parameter',
          //   roles: ['SCH_ADMIN', 'SCH_AFFAIRS'],
          //   permission: [],
          //   disabled: false
          // }
        ]
      },
      apiVersion: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/userData',
      userRole: 'user/userRole',
      pageData: 'page/pageData',
      isAPPView: 'page/isAPPView',
      isIlan: 'page/isIlan'
    }),
    bgCSS() {
      return this.$route.meta.bgCSS ? this.$route.meta.bgCSS : 'BG-01'
    },
    schoolUrl() {
      let schoolId = ''
      if (this.pageData?.schoolId) schoolId = this.pageData.schoolId
      else if (
        this.pageData?.to.split('/')[1] === 'school' &&
        this.pageData?.to.split('/')[2] !== undefined
      ) {
        schoolId = this.pageData?.to.split('/')[2]
      } else schoolId = this.userRole.school_id
      return `/school/${schoolId}`
    },
    settingPermission() {
      return (
        this.userRole.role === 'SCH_ADMIN' ||
        this.userRole.role === 'SCH_AFFAIRS'
      )
    },
    isCityAdmin() {
      return this.userRole.role === 'CITY_ADMIN'
    }
  },
  mounted: function () {
    this.getApiVersion()
    console.log(`env settings city is ilan: `, this.isIlan)
    console.log(`process env VUE_APP_CITY is: `, process.env.VUE_APP_CITY)
  },
  methods: {
    ...mapActions({
      logoutAction: 'user/logout'
    }),
    filteredItems(items) {
      return items.filter((d) => {
        if (d.roles) {
          return d.roles.includes(this.userRole.role)
        }
        return d
      })
    },
    logout() {
      this.$router.push('/login')
      this.logoutAction()
    },
    getApiVersion() {
      apiConfig.apiVersion().then((resp) => {
        this.apiVersion = resp.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-custom {
  font-size: 16px;
  width: 100%;
  padding: 5px 5px 10px 5px;
}
.drawer {
  background: rgba(255, 255, 255, 0.5) !important;
  &.v-navigation-drawer--is-mobile {
    background: rgba(255, 255, 255, 1) !important;
  }
}
</style>
