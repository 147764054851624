<template>
  <div class="px-2 py-3">
    <v-text-field
      v-model="search"
      label="搜尋"
      outlined
      rounded
      dense
      hide-details
      clearable
      clear-icon="mdi-close"
    ></v-text-field>
    <div v-if="tags">
      <router-link
        :to="tags.to"
        tag="div"
        class="pt-3 px-2 accent--text font-weight-bold"
        style="cursor: pointer"
      >
        {{ tags.name }}
      </router-link>
    </div>
    <v-treeview
      ref="options"
      :items="menuItems"
      :search="search"
      :filter="filter"
      item-key="id"
      item-text="displayName"
      item-children="branch"
      rounded
      open-all
      return-object
      hoverable
      dense
      open-on-click
    >
      <template v-slot:label="{ item }">
        <!-- <router-link :to="item.to" custom v-slot="{ navigate }"> -->
        <div
          @click.prevent.stop="navigate(item)"
          :class="[
            'nav',
            { 'secondary--text': tagPath === item.id },
            { 'accent--text': tagPath !== item.id }
          ]"
        >
          <v-icon :color="tagPath === item.id ? 'secondary' : 'accent'">{{
            item.icon
          }}</v-icon>
          {{ item.displayName }}
        </div>
        <!-- </router-link> -->
      </template>
    </v-treeview>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'

function findObjectById(array, id) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return array[i]
    } else if (Array.isArray(array[i].branch)) {
      let result = findObjectById(array[i].branch, id)
      if (result) {
        return result
      }
    }
  }
  return null
}

export default {
  name: 'LeftSideBar',
  props: {},
  components: {},
  data() {
    return {
      search: null,
      caseSensitive: false
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags'
    }),
    tagPath() {
      return this.$route.params.tagId || ''
    },
    menuItems() {
      if (!this.tags) return []
      return this.tags.branch || []
    },
    filter() {
      // 區分大小寫，自定義
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  },
  methods: {
    ...mapActions({
      getTags: 'page/getTags',
      switchSchool: 'user/switchSchool',
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    async navigate(item) {
      let link = item.to
      let from = findObjectById(this.menuItems, this.tagPath)
      let isFromSchoolOrClassroom =
        from?.to.startsWith('/school') || from?.to.startsWith('/class')
      let isGoToSchoolOrClassroom =
        link.startsWith('/school') || link.startsWith('/class')
      // check if change school
      if (isFromSchoolOrClassroom && isGoToSchoolOrClassroom) {
        let fromSchoolId = from.schoolId || from.id
        let toSchoolId = item.schoolId || item.id
        if (fromSchoolId !== toSchoolId) {
          let toSchoolName = item.schoolId
            ? findObjectById(this.menuItems, toSchoolId).displayName
            : item.displayName
          let swalOptions = {
            title: `是否前往${toSchoolName}？`,
            text: '將會離開目前頁面',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            confirmButtonColor: '#3085d6'
          }
          let result = await Swal.fire(swalOptions)
          if (!result.isConfirmed) {
            return
          } else {
            this.setOverlayLoading(true)
            await this.switchSchool(toSchoolId)
            this.setOverlayLoading(false)
          }
        }
      }
      this.$router.push(link)
    }
  },
  async mounted() {
    if (!this.tags) {
      await this.getTags()
      await this.$nextTick()
      this.$refs.options.updateAll(true)
    }
  }
}
</script>

<style lang="scss" scoped></style>
