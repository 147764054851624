var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"drawer frosted-glass-25 ems-opacity-25-bg",attrs:{"width":"auto","app":"","fixed":"","temporary":"","left":"","color":"rgba(255, 255, 255, 0.25)","overlay-color":"rgba(255, 255, 255, 0)","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"caption ma-0 text--secondary"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_vm._v("web")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.settings.version))]),_c('v-col',{staticClass:"py-0 ml-auto",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.moment(_vm.settings.versionTime).format('YYYY-MM-DD HH:mm')))])],1),(_vm.apiVersion)?_c('v-row',{staticClass:"caption ma-0 text--secondary"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_vm._v("api")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.apiVersion.version))]),_c('v-col',{staticClass:"py-0 ml-auto",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.moment(_vm.apiVersion.timestamp).format('YYYY-MM-DD HH:mm')))])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('LeftSideList')],1),(!_vm.isAPPView)?_c('v-app-bar',{attrs:{"fixed":"","app":"","height":"64px","flat":"","color":"ems-header"}},[_c('v-app-bar-nav-icon',{attrs:{"dark":"","disabled":!_vm.$store.getters['page/ipcStatus']},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":"/"}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(_vm._s(_vm.settings.title))])])],1),_c('v-spacer'),(_vm.settingPermission)?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex",attrs:{"text":"","dark":"","disabled":!_vm.$store.getters['page/ipcStatus']}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-tune")]),_c('span',{staticClass:"align-self-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.settingMenu.label)+" ")])],1)]}}],null,false,1956778519)},[_c('v-list',{staticClass:"ems-opacity-75-bg frosted-glass",attrs:{"flat":""}},_vm._l((_vm.filteredItems(_vm.settingMenu.dropDown)),function(item){return _c('v-list-item',{key:item.key,staticClass:"ems-opacity-bg frosted-glass",attrs:{"color":"primary","to":(_vm.schoolUrl + "/" + (item.to)),"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1):_vm._e(),(_vm.userRole.role === 'SCH_ADMIN' || _vm.userRole.role === 'SCH_AFFAIRS')?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex",attrs:{"text":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-help-circle-outline")]),_c('span',{staticClass:"align-self-center font-weight-bold"},[_vm._v(" 幫助中心 ")])],1)]}}],null,false,4241523698)},[_c('v-list',{staticClass:"ems-opacity-75-bg frosted-glass",attrs:{"flat":""}},[(!_vm.isIlan)?_c('v-list-item',{staticClass:"ems-opacity-bg frosted-glass",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'IssueReport' })}}},[_vm._v(" 新增報修 ")]):_vm._e(),_c('v-list-item',{staticClass:"ems-opacity-bg frosted-glass",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'OperateRecord' })}}},[_vm._v(" 操作紀錄 ")]),_c('v-list-item',{staticClass:"ems-opacity-bg frosted-glass",attrs:{"color":"primary"}},[_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"rgba(0, 0, 0, 0.87)"},attrs:{"href":"/report/manual/EMS操作手冊.pdf","download":""}},[_vm._v(" 使用手冊下載 ")])])],1)],1):_vm._e(),_c('v-menu',{attrs:{"left":"","offset-y":"","min-width":160},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1)]}}],null,false,3200971232)},[(_vm.user)?_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Hi, "+_vm._s(_vm.user.user_name))])],1),(!_vm.isCityAdmin)?_c('v-list-item',{attrs:{"to":"/line/OAuth"}},[_vm._v(" 綁定 LINE ")]):_vm._e(),_c('v-list-item',{attrs:{"to":"/line/setting"}},[_vm._v(" 告警通知設定 ")]),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-lock ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 登出")])],1)],1)],1):_c('v-list',[_c('v-list-item',{attrs:{"to":"/login"}},[_vm._v(" 登入 ")])],1)],1)],1):_vm._e(),_c('v-main',[_c('div',{staticClass:"animated fadeIn"},[_c('router-view')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }